import React, { useEffect, useState } from "react";

function Order() {
    const [isMobile, setIsMobile] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to check mobile screen and update state
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);  // Adjust breakpoint as needed
            setViewportHeight(window.innerHeight);  // Update height
            setViewportWidth(window.innerWidth);    // Update width
        };

        // Call checkMobile initially and add resize listener
        checkMobile();
        window.addEventListener("resize", checkMobile);

        // Disable scroll for the entire page (preventing scroll on the body)
        document.body.style.overflow = 'hidden';

        // Ensure the page starts at the top
        window.scrollTo(0, 0);

        // Cleanup listener and scroll behavior when component is unmounted
        return () => {
            document.body.style.overflow = '';
            window.removeEventListener("resize", checkMobile);
        };
    }, []);

    // Calculate height using dynamic viewport height and width
    const pageHeight = isMobile ? `${viewportHeight}px` : '100vh';
    const pageWidth = isMobile ? `${viewportWidth}px` : '100vw';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
                padding: 0,
                overflow: 'hidden',  // Prevent scrolling on this parent div
                height: pageHeight,     // Dynamically set height based on viewport
                width: pageWidth,       // Dynamically set width based on viewport
            }}
        >
            <iframe
                id="JotFormIFrame-242283218139052"
                title="SCRAPS - Order Now"
                allowTransparency="true"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/scrapspt/order"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    paddingBottom: isMobile ? '20px' : '0px', // Padding only for mobile
                    boxSizing: 'border-box', // Ensure padding does not affect iframe size
                }}
                scrolling="yes" // Disable scrolling within the iframe
            />
        </div>
    );
}

export default Order;
